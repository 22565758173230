body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
#login .view {
  height: 100vh;
}

#login .card {
  background-color: rgba(229, 228, 255, 0.2);
}
#login h6 {
  line-height: 1.7;
}

#login .form-check {
  margin-bottom: 0!important;
}
#login .md-form label {
  color: #ffffff;
}
#login .md-form input{
  color: #ffffff;
}
#login .md-form input[type=text]:focus:not([readonly])+label,
#login .md-form input[type=password]:focus:not([readonly])+label,
#login .md-form input[type=email]:focus:not([readonly])+label {
  color: #8EDEF8;
}
#login .md-form input[type=text]:focus:not([readonly]),
#login .md-form input[type=password]:focus:not([readonly]),
#login .md-form input[type=email]:focus:not([readonly]) {
  border-bottom: 1px solid #8EDEF8;
  box-shadow: 0 1px 0 0 #8EDEF8;
  color: #8EDEF8;
}

body {
  background-color: #eee;
}
main{
  overflow-x: hidden;
}
.fixed-sn main{
  overflow-x: visible;
}

.modal--content{
  width: 100%;
}
